import { Dispatch, SetStateAction } from 'react';
import fetchProductsFromGcJson from '@activebrands/core-web/utils/fetch-products-from-gc-json';
import { getDepictProducts } from '@activebrands/core-web/utils/grebcommerce';
import { logger } from '@activebrands/core-web/utils/logger';

interface FetchBasketOverlayProductsParams {
    marketId: string;
    pricelistId: string;
    productIds: string[];
    siteId: string;
    type: string;
}

interface DepictProduct {
    displays: {
        variant_displays: {
            display_item_id: string;
        }[];
    }[];
}

export const getProductsFromDepict = async ({
    marketId,
    pricelistId,
    productIds,
    siteId,
    type,
}: FetchBasketOverlayProductsParams) => {

    try {
        return await getDepictProducts({
            marketId,
            pricelistId,
            productIds,
            siteId,
            type,
        });
    } catch (error) {
        logger.error('Error fetching products from Depict', error);
        return [];
    }
  
};

export const setRelatedDepictProducts = async (
    related: DepictProduct,
    amount: number,
    setState: Dispatch<SetStateAction<DepictProduct[]>>
): Promise<void> => {
    try {
        if (!related || !related.displays || !Array.isArray(related.displays)) {
            throw new Error("Invalid 'related' object provided");
        }

        const productIds = related.displays
            .flatMap(display => display.variant_displays.map(variant => variant.display_item_id))
            .slice(0, amount);

        if (productIds.length === 0) {
            throw new Error("No product IDs extracted from 'related' object");
        }

        const relatedProducts: DepictProduct[] = await fetchProductsFromGcJson(productIds);

        if (!relatedProducts || !Array.isArray(relatedProducts)) {
            throw new Error("Failed to fetch products or invalid response format");
        }

        setState(prev => (JSON.stringify(prev) !== JSON.stringify(relatedProducts) ? relatedProducts : prev));
    } catch (error) {
        logger.error("Error in setRelatedDepictProducts", {
            errorMessage: (error as Error).message,
            stack: (error as Error).stack,
            related,
            amount,
        });

    }
};

export const setUpsellDepictProducts = async (
    upsell: DepictProduct,
    amount: number,
    setState: Dispatch<SetStateAction<DepictProduct[]>>
): Promise<void> => {
    try {
        if (!upsell || !upsell.displays || !Array.isArray(upsell.displays)) {
            throw new Error("Invalid 'upsell' object provided");
        }

        const productIds = upsell.displays
            .flatMap(display => display.variant_displays.map(variant => variant.display_item_id))
            .slice(0, amount);

        if (productIds.length === 0) {
            throw new Error("No product IDs extracted from 'upsell' object");
        }

        const upsellProducts: DepictProduct[] = await fetchProductsFromGcJson(productIds);

        if (!upsellProducts || !Array.isArray(upsellProducts)) {
            throw new Error("Failed to fetch products or invalid response format");
        }

        setState(prev => (JSON.stringify(prev) !== JSON.stringify(upsellProducts) ? upsellProducts : prev));
    } catch (error) {
        logger.error("Error in setUpsellDepictProducts", {
            errorMessage: (error as Error).message,
            stack: (error as Error).stack,
            upsell,
            amount,
        });
    }
};

